/** @jsx jsx */

import { Styled, jsx, Flex, Box } from "theme-ui"
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
// import Carousel from '../components/carousel'
import BasicHero from '../components/BasicHero'
import Button from "../components/button"
import Card from '../components/Card'
import { Container } from '../components/item'
import BgImage from "../components/BgImage"
import ImageArrowBlock from '../components/ImageArrowBlock'
import HorizontalCardCarousel from '../components/HorizontalCardCarousel'


import WhiteArrows from '../images/icons/button-arrows-icon.svg'

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "product-category/product-category-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    welding: file(relativePath: { eq: "welding-blanket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fireBlanket: file(relativePath: { eq: "fire-blanket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fireBlanket: file(relativePath: { eq: "fire-blanket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fireBG: file(relativePath: { eq: "dark-fire-background-compressor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blanketFolded: file(relativePath: { eq: "folded-blanket-white-border.png" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    weldingBlanket: file(relativePath: { eq: "welding-blanket.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fireBall: file(relativePath: { eq: "fireball.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heatBarrier: file(relativePath: { eq: "heat-barrier.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    infoBackground: file(relativePath: { eq: "info-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ProductCategoryPage = ({data}) => {

  const slideData = 
    [
      {
        "title": "Fire extinguisher",
        "text": "Fire extinguisher blankets are essential emergency devices at home and in the workplace. High-quality fire blankets in a home, garage, or business setting can prevent disaster in common emergencies like kitchen, fireplace, and electrical fires.",
        "itemDetails": [
          "Made from lightweight carbonized polyester wool",
          "Flash Temperature resistance up to 2192° F (1200° C)",
          "Long-term heat resistance up to 662-842° F (350°-450° C",
        ],
      },
      {
        "title": "Welding Blankets",
        "text": "Woven Silica Fiberglass welding blankets offer open spark and flame protection where needed. Can be placed on the ground or hung to provide a contained working area for welding, soldering, torch work or any other possible ignition source. They can be installed permanently or placed temporarily.",
        "itemDetails": [
            "Weight: ~ 500g/m2",
            "Thickness Options: 1.5 mm, 2.0 mm, 2.5 mm, 3.00 mm",
            "Width: 54″ ~ 59″",
            "Adjustable Length",
            "Flash Temperature resistance up to 2192° F (1200° C)",
            "Long-term heat resistance up to 662-842° F (350°-450° C)",
            "Non-melting",
            "No toxic waste gas or secondary pollution",
            "Made from woven high-silica fiberglass",
          ],
      },
      {
        "title": "Heat and Sound Barrier",
        "text": "Everywhere from under vehicle hoods, vehicle floors, generator housings, and steam pipes to workshop walls, create a buffer for safety and comfort. Draped or installed, our barrier blankets are useful wherever you need thermal and sound control.",
        "itemDetails": [
            "Weight: ~ 500g/m2",
            "Thickness Options: 1.5 mm, 2.0 mm, 2.5 mm, 3.00 mm",
            "Width: 54″ ~ 59″",
            "Adjustable Length",
            "Flash Temperature resistance up to 2192° F (1200° C)",
            "Long-term heat resistance up to 662-842° F (350°-450° C)",
            "Non-melting",
            "No toxic waste gas or secondary pollution",
            "Made from woven high-silica fiberglass",
          ],
      },
    ];


  return(
    <Layout>
      <SEO 
        title="Nexprotek Fire Blankets & Welding Blankets" 
        description="Our fire blankets are safety specific and perfect for additional security and protection. Our welding blankets are likewise, built to industry standards and provide a level of safety you would want in such a product."
      />
      
      <BasicHero 
        fluid={data.hero.childImageSharp.fluid}  
        title={"Man Welding Hero Image"} 
        heroTitle={"Nexprotek Products"}
        adjacentSectionColor={'white'}
      />
  
      <Box mt={10} as='section'>
        <ImageArrowBlock fluid={data.welding.childImageSharp.fluid} title="Welding next to blanket">
          <Styled.h3 sx={{letterSpacing: '.1em'}}>Welding Blankets</Styled.h3>
          <p sx={{variant: 'text.body'}}>Our multipurpose welding blankets protect you, your tools, and your workspace. High-performance woven high silica fiberglass with silicone resists flames and flash temperatures up to 2192° F (1200° C), with long-term heat resistance between 662-842° F (350°-450° C). Available finished in standard sizes with grommets for easy use.</p>
          <Button 
            to="/products/welding-blankets" 
            text="See Product"
            sx={{
            maxWidth: '200px',
            '@media screen and (max-width: 768px)': {
              marginTop: '20px',
              alignSelf: 'center',
            }
          }} />
        </ImageArrowBlock>
  
        <ImageArrowBlock reverse={true} fluid={data.fireBlanket.childImageSharp.fluid} title="Welding next to blanket">
        <Styled.h3 sx={{letterSpacing: '.1em'}}>Fire Blankets</Styled.h3>
        <p sx={{variant: 'text.body'}}>A fire blanket is an essential emergency device at home and in the workplace, fighting flames without the use of harmful chemicals. Non-woven carbonized polyester wool resists up to 2192° F (1200° C), with long-term heat resistance between 662-842° F (350°-450° C). Our fire blankets are reusable and non-melting, and do not produce secondary pollution. Available in standard sizes.</p>
        <Button 
          text="See Product"
          to="/products/fire-blankets"
          sx={{ 
          maxWidth: '200px',
          '@media screen and (max-width: 768px)': {
            marginTop: '20px',
            alignSelf: 'center',
          }
        }} />
      </ImageArrowBlock>
      </Box>
  
      {/* <Container>
        <Carousel />
      </Container> */}
  
      <BgImage fluid={data.fireBG.childImageSharp.fluid} title="fire background" height='auto'>
          <Flex py={[10, 14]} sx={{
            alignItems: 'center',
            '@media screen and (max-width: 768px)': {
              flexDirection: 'column',
              justifyContent: 'center',
            }
          }}>
            <Box color='white' px={10} sx={{
              flex: '1 1 60%',
              
              '@media screen and (max-width: 768px)': {
                textAlign: 'center'
              }
            }} >
              <Styled.h2>Our welding & fire blankets</Styled.h2>
              <Styled.p sx={{ variant: 'text.content' }}>Lightweight yet powerful, our welding blankets are made from woven high silica fiberglass with silicone. These materials block sparks and cut off a fire’s oxygen supply. Contain high temperatures and sparks, protect machinery, and prevent accidents.</Styled.p>
            </Box>
            <Box px={10} sx={{
              flex: '1 3 750px',
  
              '@media screen and (max-width: 768px)': {
                marginTop: '50px',
                width: '100%',
                height: 'auto',
                flex: '1'
              }
            }}>
              <Img fluid={data.blanketFolded.childImageSharp.fluid} title="a folded fire blanket" />
            </Box>
          </Flex>
      </BgImage>
      
      <Box bg='blue' as="section">
        <Container sx={{ position: 'relative', paddingTop: [0, '80px'] }}>
          <HorizontalCardCarousel data={slideData}/>
        </Container>
      </Box>
      
      <Box as="section" bg={'black'} color={'white'}>
        <Container sx={{textAlign: 'center', padding: '80px 0'}}>
          <Styled.h2>Ways to Use Your Nexprotek Fire and Welding Blankets</Styled.h2>
          <Flex py={7} sx={{
            justifyContent: 'space-between',
  
            '@media screen and (max-width: 768px)': {
              flexDirection: 'column',
              alignItems: 'center'
            }
        
          }}>
            <Card
              color='white'
              title="welding blanket"
              titleColor='white'
              fluidImg={data.weldingBlanket.childImageSharp.fluid}
              roundImage
              text="Welders from every industry need the same tools to stay safe. Our welding blankets offer protection for anyone working around flames, sparks, and high heat. "
            >
              <StyledCardLink to="/use-cases/welding-blanket-industry-uses">Learn More <WhiteArrows sx={{position: 'absolute', right: '-35px', width: '20px', height: '20px'}} /></StyledCardLink>
            </Card>
  
            <Card
              color='white'
              title="fire extinguisher blanket"
              titleColor='white'
              fluidImg={data.fireBall.childImageSharp.fluid}
              roundImage
              text="Flame-retardant cloth smothers sparks before they spread and extinguishes what water can’t. Have peace of mind with the tools to smother fires at the source."
            >
              <StyledCardLink to="/use-cases/fire-extinguisher">Learn More <WhiteArrows sx={{position: 'absolute', right: '-35px', width: '20px', height: '20px'}} /></StyledCardLink>
            </Card>
            <Card
              color='white'
              title="heat & sound barrier"
              titleColor='white'
              fluidImg={data.heatBarrier.childImageSharp.fluid}
              roundImage
              text="Create a buffer for safety and comfort. Draped, installed, or attached to sensitive surfaces, our heat barrier blankets are useful wherever you need thermal control."
            >
            <StyledCardLink to="/use-cases/heat-and-sound-barrier">Learn More <WhiteArrows sx={{position: 'absolute', right: '-35px', width: '20px', height: '20px'}} /></StyledCardLink>
          </Card>
          </Flex>
        </Container>
      </Box>
    
    </Layout>
  )
}

export default ProductCategoryPage

const StyledCardLink = styled(Link)`
    display: inline-block;
    font-weight: bold;
    color: white;
    text-decoration: none;
    position: relative;
    margin-top: 20px;

`
